<template>
  <div class="z-map">
    <l-map
      ref="map"
      :zoom="zoom"
      :center="center"
      @click="handleClick"
      @ready="onMapReady"
    >
      <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <template v-if="incidences">
        <l-marker
          v-for="(incidence, index) in incidences"
          :key="`incidence-${index}`"
          :lat-lng="incidence.position"
          :icon="customIcon.incidence"
        >
        </l-marker>
      </template>
      <template v-if="destinations">
        <l-marker
          v-for="(destination, index) in destinations"
          :class="[
            { 'is-destination': !(markOrigin && destination.order === 0) }
          ]"
          :key="`destination-${index}`"
          :lat-lng="destination.position"
          :icon="
            markOrigin && destination.order === 0
              ? customIcon.origin
              : customIcon.destination
          "
        >
          <l-popup>{{ destination.name }}</l-popup>
        </l-marker>
      </template>
      <l-polyline
        v-if="coordinates"
        :lat-lngs="coordinates"
        color="#0076ff"
        :opacity="0.7"
        :weight="5"
      >
      </l-polyline>
      <slot></slot>
      <slot name="driver">
        <div v-if="driver">
          <z-map-driver :driver="driver" />
        </div>
      </slot>
      <slot name="drivers">
        <div v-if="drivers">
          <l-marker
            v-for="(driver, index) in drivers"
            :key="`drivers-${index}`"
            :lat-lng="driver.position"
            :icon="customIcon.driver"
          >
            <l-popup>{{ driver.name }}</l-popup>
          </l-marker>
        </div>
      </slot>
    </l-map>
  </div>
</template>

<script>
import { getTimeEstimation } from "../utils/service-time";
import ZMapDriver from "./ZMapDriver";
import MapMixin from "../mixins/map";
import { LMap, LMarker, LPolyline, LPopup, LTileLayer } from "vue2-leaflet";
import "leaflet/dist/leaflet.js";
import "leaflet-routing-machine/dist/leaflet-routing-machine.js";

export default {
  name: "ZMap",

  components: {
    ZMapDriver,
    LMap,
    LMarker,
    LPolyline,
    LPopup,
    LTileLayer
  },

  mixins: [MapMixin],

  props: {
    reverseGeocode: {
      type: Boolean,
      default: false
    },
    drivers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      map: null,
      coordinates: []
    };
  },

  watch: {
    route(value) {
      this.coordinates = value;
    },
    destinations(value) {
      if (value.length > 0) this.getRoute();
    }
  },

  methods: {
    onMapReady(mapObject) {
      this.map = mapObject;
      if (this.calculateRoute && this.destinations.length > 0) {
        this.getRoute();
      } else {
        this.coordinates = this.route;

        const wayPoints = this.destinations.map(dest => {
          return new L.Routing.Waypoint(dest.position);
        });

        if (wayPoints.length > 0) {
          this.centerMap(wayPoints);
        }
      }

      // This fixes the problem of the map not fully loading
      setTimeout(() => {
        this.map.invalidateSize();
      }, 1000);
    },

    getRoute() {
      const wayPoints = this.destinations.map(dest => {
        return new L.Routing.Waypoint(dest.position);
      });

      if (wayPoints.length > 0) {
        this.centerMap(wayPoints);
      }

      if (wayPoints.length > 1) {
        this.router.route(wayPoints, (err, routes) => {
          // Set fallback url in case of error
          if (
            err &&
            this.router.options.serviceUrl !==
              process.env.VUE_APP_FALLBACK_ROUTING_URL
          ) {
            this.router.options.serviceUrl =
              process.env.VUE_APP_FALLBACK_ROUTING_URL;
            this.getRoute();
          }

          if (routes && routes[0] && routes[0].coordinates) {
            this.coordinates = routes[0].coordinates;
            this.emitRates(routes);
          }
        });
      } else {
        this.coordinates = [];
      }
    },

    centerMap(wayPoints) {
      const bounds = wayPoints.map(el => {
        const { lat, lng } = el.latLng;
        return new L.LatLng(lat, lng);
      });
      this.map.fitBounds(bounds, { padding: [50, 50] });
    },

    emitRates(routes) {
      let minutes = 0;
      let shortestKm = routes[0].summary.totalDistance;

      for (let i = 1; i < routes.length; i++) {
        if (routes[i].summary.totalDistance < shortestKm) {
          shortestKm = routes[i].summary.totalDistance;
        }
      }
      minutes = getTimeEstimation(shortestKm);
      const km = parseFloat(parseFloat(shortestKm / 1000).toFixed(2));

      this.$emit("rate-change", { km, minutes });
    },

    handleClick(event) {
      this.$emit("click", event);

      if (this.reverseGeocode) {
        this.decodeLatLong(event.latlng);
      }
    },

    decodeLatLong(position) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode(
        {
          location: {
            lat: position.lat,
            lng: position.lng
          }
        },
        (results, status) => {
          if (status === "OK") {
            const resultAddress = results[0];
            this.$emit("decoded-position", {
              address: resultAddress.formatted_address,
              position: {
                lat: resultAddress.geometry.location.lat(),
                lng: resultAddress.geometry.location.lng()
              }
            });
          }
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "../../../../node_modules/leaflet/dist/leaflet.css";
@import "../../../../node_modules/leaflet-routing-machine/dist/leaflet-routing-machine.css";

.z-map {
  .driver-image img {
    height: 40px;
  }

  .leaflet-pane .leaflet-marker-pane .leaflet-marker-icon:not(:first-child) {
    filter: hue-rotate(323deg);
  }
}
</style>
