// Time in minutes
const time = {
  SHORT: 45,
  MEDIUM: 60,
  LARGE: 90
};

// Distance in meters
const distance = {
  SHORT: 4000,
  LARGE: 10000
};

export default {
  time,
  distance
};
