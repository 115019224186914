export default {
  props: {
    calculateRoute: {
      type: Boolean,
      default: false
    },
    destinations: {
      type: Array,
      default: () => []
    },
    driver: {
      type: Object,
      default: null
    },
    incidences: {
      type: Array,
      default: () => []
    },
    markOrigin: {
      type: Boolean,
      default: true
    },
    route: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 13
    },
    center: {
      type: [Array, Object],
      default: () => [20.6737777, -103.4054536]
    }
  },

  data() {
    return {
      url:
        "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
      router: new L.Routing.osrmv1({
        serviceUrl: process.env.VUE_APP_ROUTING_URL
      }),
      customIcon: {
        origin: this.makeIcon(require("../assets/img/pin-store.png")),
        destination: this.makeIcon(require("../assets/img/pin-house.png"), {
          popupAnchor: [-6, -76]
        }),
        driver: this.makeIcon(require("../assets/img/ic_pin_driver.png")),
        incidence: this.makeIcon(require("../assets/img/icon-error.png"), {
          popupAnchor: [-3, -76]
        }),
        alphaDriver: this.makeIcon(require("../assets/img/ic-moto-vip.png"), {
          popupAnchor: [-3, -76]
        }),
        normalDriver: this.makeIcon(require("../assets/img/ic-moto.png"), {
          popupAnchor: [-3, -76]
        })
      }
    };
  },

  methods: {
    makeIcon(image, options = {}) {
      const { size, anchor, popupAnchor } = options;
      return L.icon({
        iconUrl: image,
        iconSize: size || [34, 40],
        iconAnchor: anchor || [17, 40],
        popupAnchor: popupAnchor || [0, -40]
      });
    }
  }
};
