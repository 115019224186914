import Estimation from "../constants/services/estimation";

export function getTimeEstimation(estimatedDistance) {
  if (estimatedDistance <= Estimation.distance.SHORT) {
    return Estimation.time.SHORT;
  }
  if (
    estimatedDistance > Estimation.distance.SHORT &&
    estimatedDistance <= Estimation.distance.LARGE
  ) {
    return Estimation.time.MEDIUM;
  }
  if (estimatedDistance > Estimation.distance.LARGE) {
    return Estimation.time.LARGE;
  }
  return 0;
}
