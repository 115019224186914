<template>
  <div class="z-map-driver">
    <l-marker class="driver-icon" :lat-lng="position">
      <l-icon :icon-anchor="[20, 40]" :icon-size="[40, 40]">
        <div class="driver-image" :class="orientation">
          <img v-if="isAlpha" src="../assets/img/ic-moto-vip.png" />
          <img v-else src="../assets/img/ic-moto.png" />
        </div>
      </l-icon>
    </l-marker>
  </div>
</template>

<script>
import { LIcon, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.js";

export default {
  name: "ZMapDriver",

  components: {
    LIcon,
    LMarker
  },

  props: {
    driver: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      orientation: "S"
    };
  },

  computed: {
    position() {
      return this.driver.position;
    },
    isAlpha() {
      return this.driver.alpha;
    }
  },

  watch: {
    driver: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.orientation = newVal?.orientation?.exact || this.orientation;
      }
    }
  }
};
</script>

<style lang="scss">
.driver-image {
  &.N {
    transform: rotate(0deg);
  }

  &.NNE {
    transform: rotate(22.5deg);
  }

  &.NE {
    transform: rotate(45deg);
  }

  &.ENE {
    transform: rotate(67.5deg);
  }

  &.E {
    transform: rotate(90deg);
  }

  &.ESE {
    transform: rotate(112.5deg);
  }

  &.SE {
    transform: rotate(135deg);
  }

  &.SSE {
    transform: rotate(157.5deg);
  }

  &.S {
    transform: rotate(180deg);
  }

  &.SSW {
    transform: rotate(202.5deg);
  }

  &.SW {
    transform: rotate(225deg);
  }

  &.WSW {
    transform: rotate(247.5deg);
  }

  &.W {
    transform: rotate(270deg);
  }

  &.WNW {
    transform: rotate(292.5deg);
  }

  &.NW {
    transform: rotate(315deg);
  }

  &.NNW {
    transform: rotate(337.5deg);
  }
}
</style>
